import { useMemo } from 'react';
import { ProjectCard } from '../ProjectCard';
import { useGetProjects, useTranslation } from '../../hooks';
import { SkeletonList } from '../SkeletonList';
import { ProjectEmptyState } from './ProjectsEmptyState';
import { ProjectSearchBar } from './ProjectSearchBar';
import { useSearchParams } from 'react-router-dom';

export const ProjectContainer: React.FC = () => {
  const { data, isLoading } = useGetProjects();
  const [searchParams] = useSearchParams();
  const t = useTranslation();

  const filteredData = useMemo(() => {
    if (searchParams.get('search')) {
      return data?.filter(project =>
        project.name
          .toLowerCase()
          .includes(searchParams.get('search')?.toLowerCase() ?? ''),
      );
    }
    return data;
  }, [data, searchParams]);

  const renderProjects = useMemo(
    () =>
      filteredData?.map(project => {
        return <ProjectCard key={project.projectId} {...project} />;
      }),
    [filteredData],
  );

  return (
    <>
      <div className="flex flex-col gap-3 mt-8 ">
        <div className="flex md:justify-end">
          <ProjectSearchBar />
        </div>
        {!!filteredData?.length && (
          <h4 className="text-xl">
            {t('projects.numberOfProjects', { count: filteredData?.length })}
          </h4>
        )}
        <div className="grid grid-cols-4 mt-6 gap-8">
          {isLoading ? (
            <SkeletonList count={5} itemClassName="w-full h-52 rounded-md" />
          ) : (
            renderProjects
          )}
        </div>
      </div>
      {filteredData?.length === 0 && <ProjectEmptyState />}
    </>
  );
};
