import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '../../types';

export const Dashboard: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(`/${PATHS.DASHBOARD}/${PATHS.PROJECTS}`);
  }, [navigate]);

  return <></>;
};
