import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'me-component-library';
import { useTranslation, useTriggerBulkUserCreation } from '../../hooks';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

export const BulkUserCreateModal: React.FC = () => {
  const t = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { mutate, isLoading } = useTriggerBulkUserCreation();
  const params = useParams();

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleImport = () => {
    mutate(
      {
        projectId: params.id ?? '',
      },
      {
        onSuccess: () => {
          closeModal();
        },
      },
    );
  };

  return (
    <Dialog open={isModalOpen}>
      <DialogTrigger onClick={openModal} asChild>
        <Button>
          {t('projectDetail.userList.bulkCreate.openModalButton')}
        </Button>
      </DialogTrigger>
      <DialogContent onCloseClick={closeModal}>
        <DialogHeader>
          <DialogTitle>
            {t('projectDetail.userList.bulkCreate.title')}
          </DialogTitle>
          <DialogDescription>
            {t('projectDetail.userList.bulkCreate.subtitle')}
          </DialogDescription>
        </DialogHeader>
        <Button isLoading={isLoading} onClick={handleImport}>
          {t('projectDetail.userList.bulkCreate.actionButton')}
        </Button>
      </DialogContent>
    </Dialog>
  );
};
