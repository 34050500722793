import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'me-component-library';
import { CustomError, Project } from '../../types';
import { useTranslation } from '../useTranslation';
import { UpdateDomain, updateDomain } from '../../api/updateDomain';

export function useUpdateDomain(projectId: string) {
  const t = useTranslation();
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation<Project, CustomError, UpdateDomain>(
    (domain: UpdateDomain) => updateDomain({ ...domain, projectId }),
    {
      onSuccess: () => {
        toast({
          title: t('projectDetail.appearanceForm.toast.success.title'),
          description: t(
            'projectDetail.appearanceForm.toast.success.description',
          ),
        });
        queryClient.invalidateQueries(['projects', projectId]);
      },
      onError: () => {
        toast({
          title: t('projectDetail.appearanceForm.toast.error.title'),
          description: t(
            'projectDetail.appearanceForm.toast.error.description',
          ),
          variant: 'error',
        });
      },
    },
  );

  return { mutate, isLoading };
}
