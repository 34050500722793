import projects from './assets/projects.json';
import projectDetail from './assets/projectDetail.json';
import autoLogin from './assets/autoLogin.json';
import footer from './assets/footer.json';
import navbar from './assets/navbar.json';
import notfound from './assets/notfound.json';

export const en = {
  translation: {
    projects,
    projectDetail,
    autoLogin,
    footer,
    navbar,
    notfound
  },
};
