import { useState } from 'react';
import { Footer } from '../Footer';
import { useAuthManager, useTranslation } from '../../hooks';
import { Button } from 'me-component-library';
import { LanguageSelector } from '../LanguageSelector';

export const LoginWithMittwald = () => {
  const [isLoading, setIsLoading] = useState(false);
  const auth = useAuthManager();
  const t = useTranslation();

  return (
    <div className="flex min-h-screen flex-1 flex-col bg-zinc-100">
      <div className="flex justify-end rounded p-5">
        <div className="bg-gray-200 rounded p-2">
          <LanguageSelector />
        </div>
      </div>
      <div className="flex justify-center mt-10">
        <div className="flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow w-full mx-3 md:mx-0 md:w-2/4 xl:w-1/4">
          <div className="mt-10">
            <img src="/default.png" alt="postmaster logo" />
          </div>
          <h2 className="mt-5 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            {t('autoLogin.login.title')}
          </h2>
          <Button
            className="w-44 min-w-44 my-10"
            onClick={async () => {
              setIsLoading(true);
              await auth.signinRedirect();
              setIsLoading(false);
            }}
          >
            {isLoading ? '...' : t('autoLogin.login.cta')}
          </Button>
        </div>
      </div>
      <Footer />
    </div>
  );
};
