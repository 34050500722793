import { useQuery, useQueryClient } from 'react-query';
import { getProjects } from '../../api';

export function useGetProjects() {
  const queryClient = useQueryClient();

  return useQuery('projects', () => getProjects(), {
    refetchOnWindowFocus: false,
    onSuccess: data => {
      data.forEach(project => {
        queryClient.setQueryData(['project', project.projectId], project);
      });
    },
  });
}
