import { useEffect, useRef, useState } from 'react';
import { Button } from 'me-component-library';
import { useNavigate } from 'react-router-dom';
import { useAuthManager, useTranslation } from '../../hooks';
import { CenteredFullScreenLoadingSpinner } from '../CenteredFullScreenLoadingSpinner';

export const OAuthCallback: React.FC = () => {
  const auth = useAuthManager();
  const [error, setError] = useState(false);
  const hasCalled = useRef(false);
  const navigate = useNavigate();
  const t = useTranslation();

  useEffect(() => {
    const signIn = async () => {
      try {
        if (hasCalled.current) {
          return;
        }
        hasCalled.current = true; //don't remove - or else react will double render in dev mode, which will not work

        const user = await auth.signinCallback();
        if (user) {
          // user.access_token = mittwald token
          localStorage.setItem('accessToken', user.access_token);
          navigate('/');
        } else {
          setError(true);
        }
      } catch (e) {
        setError(true);
      }
    };
    signIn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error) {
    return (
      <div className="w-full bg-gray-200 h-screen flex items-center justify-center flex-col gap-5 select-none">
        <div className="mb-12">
          <img src="/default.png" alt="postmaster logo" />
        </div>
        <div className="flex items-center flex-col">
          <div className="text-6xl mb-6">:(</div>
          <div className="text-red-500 text-xl">
            {t('autoLogin.error.title')}
          </div>
          <Button
            onClick={() => navigate('/')}
            variant="default"
            className="mt-12"
          >
            {t('autoLogin.error.cta')}
          </Button>
        </div>
      </div>
    );
  }

  return <CenteredFullScreenLoadingSpinner />;
};
