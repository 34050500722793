import { createContext } from 'react';
import { UserManager, Log } from 'oidc-client-ts';

Log.setLogger(console);

const userMangager = new UserManager({
  authority: 'https://api.mittwald.de/v2/oauth2/',
  client_id: import.meta.env.VITE_PUBLIC_OAUTH_CLIENT_ID,
  redirect_uri: import.meta.env.VITE_PUBLIC_OAUTH_CALLBACK_URL,
  scope:
    'user:read customer:read customer:write project:read mail:read mail:write mail:delete',
  response_type: 'code',
  metadata: {
    authorization_endpoint: 'https://api.mittwald.de/v2/oauth2/authorize',
    token_endpoint: 'https://api.mittwald.de/v2/oauth2/token',
  },
});

export const AuthContext = createContext(userMangager);
