import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthManager } from '../../hooks';
import { CenteredFullScreenLoadingSpinner } from '../CenteredFullScreenLoadingSpinner';

export const OAuthEntryPoint: React.FC = () => {
  const auth = useAuthManager();
  const hasCalled = useRef(false);
  const navigate = useNavigate();

  useEffect(() => {
    const signIn = async () => {
      try {
        if (hasCalled.current) {
          return;
        }
        hasCalled.current = true; //don't remove - or else react will double render in dev mode, which will not work
        await auth.signinRedirect();
      } catch (e) {
        console.error('entrypoint redirect failed', e);
        navigate('/');
      }
    };
    signIn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <CenteredFullScreenLoadingSpinner />;
};
