import { useMutation, useQueryClient } from 'react-query';
import { UpdateProjectDetails, updateProjectDetails } from '../../api';
import { toast } from 'me-component-library';
import { CustomError, Project } from '../../types';
import { useTranslation } from '../useTranslation';

export function useUpdateProjectDetails(projectId: string) {
  const t = useTranslation();
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation<
    Project,
    CustomError,
    UpdateProjectDetails
  >(
    (projectDetails: UpdateProjectDetails) =>
      updateProjectDetails({ ...projectDetails, projectId }),
    {
      onSuccess: () => {
        toast({
          title: t('projectDetail.appearanceForm.toast.success.title'),
          description: t(
            'projectDetail.appearanceForm.toast.success.description',
          ),
        });
        queryClient.invalidateQueries(['projects', projectId]);
      },
      onError: () => {
        toast({
          title: t('projectDetail.appearanceForm.toast.error.title'),
          description: t(
            'projectDetail.appearanceForm.toast.error.description',
          ),
          variant: 'error',
        });
      },
    },
  );

  return { mutate, isLoading };
}
