import { useParams } from 'react-router-dom';
import { useGetProjectDetail } from '../../hooks';
import { Card, Skeleton } from 'me-component-library';
import { Globe, Hourglass, Mailbox } from 'lucide-react';

export const ProjectInfo: React.FC = () => {
  const params = useParams();
  const { data, isLoading } = useGetProjectDetail(params.id as string);

  if (isLoading) return <Skeleton className="w-full h-full" />;

  return (
    <Card className="p-5 w-96">
      <div className="text-lg overflow-hidden overflow-ellipsis whitespace-nowrap">
        {data?.name}
      </div>
      {data?.domain && data.domain.length > 0 && (
        <div className="flex gap-3 mt-1 text-slate-400 overflow-hidden overflow-ellipsis whitespace-nowrap">
          <span>
            <span>{data.isDomainVerified ? <Globe /> : <Hourglass />}</span>
          </span>
          <a
            href={'https://' + data?.domain}
            rel="noopener norefferer"
            target="_blank"
          >
            {data?.domain}
          </a>
        </div>
      )}
      {data?.enableCustomSenderEmail && (
        <div className="flex gap-3 mt-1 text-slate-400 overflow-hidden overflow-ellipsis whitespace-nowrap select-none">
          <span>
            <Mailbox />
          </span>
          <div>{data?.customSenderEmail}</div>
        </div>
      )}
    </Card>
  );
};
