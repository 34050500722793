import { useQuery, useQueryClient } from 'react-query';
import { getProjectUsers } from '../../api';

export function useGetProjectUsers(projectId: string) {
  const queryClient = useQueryClient();

  return useQuery([`users`, projectId], () => getProjectUsers(projectId), {
    onSuccess: data => {
      data.forEach(user => {
        queryClient.setQueryData(['users', projectId, user.id], user);
      });
    },
  });
}
