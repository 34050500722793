import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import * as z from 'zod';

import { useTranslation } from '../../hooks';
import { useParams } from 'react-router-dom';

import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  Input,
  Label,
} from 'me-component-library';
import { Project } from '../../types';
import { useUpdateDomain } from '../../hooks/useUpdateDomain';
import { DomainSettingsStatusBox } from './DomainSettingsStatusBox';
import { DomainSettingsSetupInstructions } from './DomainSettingsSetupInstructions';

type Props = {
  data: Project;
};

export const DomainSettingsEdit: React.FC<Props> = ({ data }) => {
  const t = useTranslation();
  const params = useParams();
  const { mutate, isLoading } = useUpdateDomain(params.id as string);

  const formSchema = z.object({
    domain: z.string(),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      domain: data?.domain,
    },
  });

  function onSubmit(data: z.infer<typeof formSchema>) {
    //remove domain
    const newData = { ...data, domain: '' };

    mutate(
      { projectId: params.id as string, ...newData },
      {
        onSuccess: () => {
          form.reset({}, { keepValues: true });
        },
      },
    );
  }

  return (
    <>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="w-full">
          <div className="flex flex-row justify-between flex-wrap gap-12 border border-slate-200 rounded-lg mt-6 py-8 px-12">
            <div className="flex">
              <div className="flex flex-col w-full gap-4">
                <FormField
                  control={form.control}
                  name="domain"
                  render={({ field }) => (
                    <FormItem>
                      <Label className="text-xl text-slate-700">
                        {t('projectDetail.domain.fields.editDomain.label')}
                      </Label>
                      <p className="text-sm text-slate-500 mt-0.5">
                        {t('projectDetail.domain.fields.editDomain.help')}
                      </p>
                      <FormControl>
                        <div className="w-96">
                          <Input type="text" {...field} disabled={true} />
                        </div>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <div className="flex w-auto gap-4 mt-6">
                  <Button
                    type="submit"
                    isLoading={isLoading}
                    disabled={isLoading}
                  >
                    {t('projectDetail.domain.fields.editDomain.buttons.remove')}
                  </Button>
                </div>
              </div>
            </div>
            <DomainSettingsStatusBox isVerified={data.isDomainVerified} />
          </div>
        </form>
      </Form>
      {data.isDomainVerified === false && (
        <div className="mt-4">
          <DomainSettingsSetupInstructions
            domain={data.domain}
            projectId={data.projectId}
          />
        </div>
      )}
    </>
  );
};
