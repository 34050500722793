import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  Icon,
} from 'me-component-library';
import { useLogout, useTranslation, useUser } from '../../hooks';
import { UserAvatar } from '../UserAvatar';

export const UserMenu: React.FC = () => {
  const { mutate, isLoading } = useLogout();
  const { data } = useUser();
  const t = useTranslation();

  function onClick() {
    mutate();
  }
  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <UserAvatar />
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuLabel>{data?.email}</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={onClick} className="flex gap-2">
          <Icon name={isLoading ? 'loader' : 'logout'} size={16} />
          <span>{t('navbar.logout')}</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
