import { RouteObject } from 'react-router-dom';
import { PATHS } from './types/paths';
import {
  Home,
  Login,
  Dashboard,
  Projects,
  ProjectDetail,
  Callback,
} from './pages';
import { Layout } from './components/Layout';
import { PrivateRoutes } from './components/PrivateRoutes/PrivateRoutes';
import { NotFound } from './components/NotFound';
import { EntryPoint } from './pages/Oauth/EntryPoint';

export const routes: RouteObject[] = [
  {
    path: PATHS.INDEX,
    children: [
      {
        path: '',
        element: <Home />,
      },
      {
        path: PATHS.LOGIN,
        element: <Login />,
      },
    ],
  },
  {
    path: PATHS.DASHBOARD,
    element: (
      <PrivateRoutes>
        <Layout />
      </PrivateRoutes>
    ),
    children: [
      {
        path: '',
        element: <Dashboard />,
      },
      {
        path: PATHS.PROJECTS,
        children: [
          {
            path: '',
            element: <Projects />,
          },
          {
            path: PATHS.ENTITY,
            element: <ProjectDetail />,
          },
        ],
      },
    ],
  },
  {
    path: PATHS.OAUTH_CALLBACK,
    element: <Callback />,
  },
  {
    path: PATHS.OAUTH_ENTRYPOINT,
    element: <EntryPoint />,
  },
  {
    path: '*',
    element: <NotFound />,
  },
];
