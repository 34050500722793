import { Button } from 'me-component-library';
import emptyStateImage from '../../assets/empty_state_projects.svg';
import { useTranslation } from '../../hooks';

export const ProjectEmptyState: React.FC = () => {
  const t = useTranslation();

  return (
    <div
      className="flex flex-col gap-4 w-full mt-8 items-center justify-center max-w-sm m-auto"
      data-testid="empty-state"
    >
      <div className="w-48 h-48">
        <img
          src={emptyStateImage}
          alt="empty state"
          className="w-full h-full object-contain"
        />
      </div>
      <div className="flex flex-col gap-1 items-center justify-center text-center">
        <h2 className="text-lg text-slate-600">
          {t('projects.emptyState.title')}
        </h2>
        <p className="text-sm text-slate-400 my-4">
          {t('projects.emptyState.description')}
        </p>
        <a
          href="https://mstudio.mittwald.de"
          target="_blank"
          rel="noreferrer noopener"
        >
          <Button>{t('projects.mStudioNudge.button')}</Button>
        </a>
      </div>
    </div>
  );
};
