import { api } from './api';

type UploadImageResponse = {
  url: string;
};

export type UploadProjectLogoAttributes = {
  projectId: string;
  image: File;
  imageType: 'logo' | 'favicon' | 'title' | 'topImage';
};

export async function uploadImage({
  image,
  projectId,
  imageType,
}: UploadProjectLogoAttributes): Promise<UploadImageResponse> {
  const imageFormData = new FormData();
  imageFormData.append('image', image);

  const response = await api.post(
    `admin/projects/${projectId}/image?type=${imageType}`,
    {
      body: imageFormData,
    },
  );

  return response.json();
}
