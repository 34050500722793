import { Project } from '../types';
import { api } from './api';

type ProjectDomain = {
  domain: string;
};

export type UpdateDomain = ProjectDomain & { projectId: string };

export async function updateDomain({
  projectId,
  ...domain
}: UpdateDomain): Promise<Project> {
  const response = await api.patch(`admin/projects/${projectId}/domain`, {
    json: {
      ...domain,
    },
  });

  return response.json();
}
