import { useMutation, useQueryClient } from 'react-query';
import { logout } from '../../api/logout';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '../../types';
import { useAuthManager } from '../useAuthManager';

export function useLogout() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const authManger = useAuthManager();

  return useMutation(logout, {
    onSuccess: () => {
      localStorage.removeItem('accessToken');
      queryClient.clear();
      authManger.removeUser();
      navigate(`/${PATHS.LOGIN}`);
    },
  });
}
