import { ProjectListItem } from '../types';
import { api } from './api';

interface ApiResponse {
  projects: ProjectListItem[];
}

export async function getProjects(): Promise<ProjectListItem[]> {
  try {
    const response = await api.get('admin/projects');
    const data: ApiResponse = await response.json();

    return data.projects;
  } catch (error) {
    console.error('Error fetching projects:', error);
    throw error;
  }
}
