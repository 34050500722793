import { useEffect } from 'react';
import { useTranslation } from '../../hooks';

export const Footer = () => {
  const t = useTranslation();
  useEffect(() => {
    const script = document.createElement('script');
    script.src =
      'https://sdk.floppy.digital/sdk.smart-feedback.digital/main.0.0.36.min.js';
    script.async = true;
    script.integrity =
      'sha384-59WVds9J82K1MAq4hPclmiTVZZFkXM3K//EAtQJNQ3yGPYD7lBhWm3vMkiETNOE7';
    script.crossOrigin = 'anonymous';
    document.body.appendChild(script);
    window.projectId = 'cm0f9sj640000ktomxnztzkf8';
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <div className="flex px-12 m-auto max-w-screen-2xl w-2/4 h-4/6 flex-grow">
        <div id="report-app"></div>
      </div>
      <div className="overflow-hidden">
        <footer className="bg-gray-200 py-4 sticky bottom-0 flex justify-center">
          {t('footer.title')}
          <div className="ml-1 text-slate-600">
            <a href="mailto:support@weissaufschwarz.digital">
              {' '}
              support@weissaufschwarz.digital
            </a>{' '}
          </div>
        </footer>
        <footer className="bg-gray-900 inset-x-0 relative bottom-0 text-xs text-slate-400 px-4 py-3">
          <ul className="flex flex-row flex-nowrap md:justify-start gap-5 md:gap-8 items-center">
            <li>
              <a
                href="https://weissaufschwarz.digital/imprint"
                target="_blank"
                rel="noopener norefferer"
              >
                {t('footer.imprint')}
              </a>
            </li>
          </ul>
        </footer>
      </div>
    </>
  );
};
