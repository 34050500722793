import { Link } from 'react-router-dom';
import { ProjectListItem } from '../../types';
import { useGetWhiteLabelThemes } from '../../hooks';
import { cn } from 'me-component-library';
import { Gem, Mailbox } from 'lucide-react';

export const ProjectCard: React.FC<ProjectListItem> = ({
  domain,
  logo,
  name,
  projectId,
  theme,
  enableCustomSenderEmail,
}) => {
  const { getSelectedThemeStyle } = useGetWhiteLabelThemes();
  const { background, borderColor } = getSelectedThemeStyle(theme);
  return (
    <Link to={projectId}>
      <div
        className={cn(
          'flex flex-col relative gap-4 p-6 h-52 justify-center rounded-md shadow-md  border border-slate-200 cursor-pointer hover:shadow-xl transition-all ease-in-out overflow-hidden after:w-full after:h-2  after:absolute after:bottom-0 after:left-0 hover:after:h-3 after:ease-in-out after:transition-all',
          `after:${background}`,
          `hover:${borderColor}`,
        )}
        data-testid="project-card"
      >
        {enableCustomSenderEmail && (
          <span className="flex gap-1 absolute right-0 top-0 p-2">
            <Gem />
            <Mailbox />
          </span>
        )}
        <div className="w-12 h-12 rounded-md border border-slate-200 p-2">
          <img
            src={logo === '' ? '/default-icon-72x72.png' : logo}
            alt={name}
            className="w-full h-full object-contain"
          />
        </div>
        <div className="min-h-14">
          <h2 className="text-xl max-w-full truncate">{name}</h2>
          <p className="mt-1 underline">{domain}</p>
        </div>
      </div>
    </Link>
  );
};
