import { useAuthManager } from '../../hooks';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '../../types';
import { useEffect, useState } from 'react';
import { LoginWithMittwald } from '../LoginWithMittwald';
import { CenteredFullScreenLoadingSpinner } from '../CenteredFullScreenLoadingSpinner';

export const AutoLogin = () => {
  const navigate = useNavigate();
  const auth = useAuthManager();
  const [userFound, setUserFound] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const signIn = async () => {
      try {
        const user = await auth.getUser();
        if (user) {
          // found session
          if (
            localStorage.getItem('accessToken') === null ||
            localStorage.getItem('accessToken') === undefined
          ) {
            localStorage.setItem('accessToken', user.access_token);
          }
          setUserFound(true);
          navigate(`/${PATHS.DASHBOARD}`);
        }
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    };

    signIn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isLoading && !userFound) {
    return <LoginWithMittwald />;
  }

  return <CenteredFullScreenLoadingSpinner />;
};
