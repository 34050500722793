import {
  Button,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'me-component-library';
import { useTranslation } from '../../hooks';
import { useState } from 'react';

interface EmailTemplatePreviewModalProps {
  subject: string;
  html: string;
  sender: string;
}

export const EmailTemplatePreviewModal: React.FC<
  EmailTemplatePreviewModalProps
> = ({ subject, html, sender }) => {
  const t = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Dialog open={isModalOpen}>
      <DialogTrigger onClick={openModal} asChild>
        <Button>
          {t('projectDetail.emailTemplateForm.preview.modalButton')}
        </Button>
      </DialogTrigger>
      <DialogContent onCloseClick={closeModal} className="text-slate-700">
        <DialogHeader>
          <DialogTitle>
            {t('projectDetail.emailTemplateForm.preview.modalButton')}
          </DialogTitle>
        </DialogHeader>
        <div className="text-sm">
          {t('projectDetail.tabs.emailSender')}: {sender}
        </div>
        <div className="text-sm">
          {t('projectDetail.emailTemplateForm.fields.subject.label')}:
        </div>
        <div dangerouslySetInnerHTML={{ __html: subject }} />{' '}
        <div className="text-sm">
          {t('projectDetail.emailTemplateForm.fields.html.label')}:
        </div>
        <div className="overflow-y-auto max-h-80">
          <div dangerouslySetInnerHTML={{ __html: html }} />
        </div>
      </DialogContent>
    </Dialog>
  );
};
