import { useEffect, useState } from 'react';
import i18next from 'i18next';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Icon,
} from 'me-component-library';

export const LanguageSelector = () => {
  const [locale, setLocale] = useState(i18next.language);

  const handleLocaleChange = (newLocale: string) => {
    setLocale(newLocale);
  };

  useEffect(() => {
    i18next.changeLanguage(locale.toLowerCase());
  }, [locale]);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <div className="flex items-center gap-2 select-none">
          {locale.includes('en') ? 'EN' : 'DE'}
          <Icon name="chevronDown" size={20} />
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuItem
          onClick={() =>
            handleLocaleChange(locale.includes('en') ? 'de' : 'en')
          }
          className="flex gap-2"
        >
          {locale.includes('en') ? 'DE' : 'EN'}
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
