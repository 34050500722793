import { useQuery } from 'react-query';
import { getProjectDetails } from '../../api';

export function useGetProjectDetail(projectId: string) {
  return useQuery(['projects', projectId], () => getProjectDetails(projectId), {
    refetchOnWindowFocus: false,
    staleTime: 30000,
    refetchInterval: 30000,
  });
}
