import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { PATHS } from '../../types';
import { getUserProfile } from '../../api/getUserProfile';

type Options = {
  navigateToLoginOnError?: boolean;
};
export function useUser(options?: Options) {
  const navigate = useNavigate();
  return useQuery('user', () => getUserProfile(), {
    refetchOnWindowFocus: false,
    retry: false,
    onError: () => {
      if (options?.navigateToLoginOnError) {
        // clear local storage
        localStorage.removeItem('accessToken');
        // clear session storage
        sessionStorage.clear();

        navigate(`/${PATHS.LOGIN}`);
      }
    },
  });
}
