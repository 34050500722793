import { api } from './api';

export type TriggerInviteEmailResendParams = {
  projectId: string;
  userId: string;
  language: 'de' | 'en';
};

export async function triggerInviteEmailResend({
  projectId,
  userId,
  language,
}: TriggerInviteEmailResendParams) {
  const response = await api.post(
    `admin/projects/${projectId}/users/${userId}/resend-invite`,
    {
      json: {
        language,
      },
    },
  );
  return response.status === 204;
}
