import { api } from './api';

export type TriggerBulkUserCreationParams = {
  projectId: string;
};

export async function triggerBulkUserCreation({
  projectId,
}: TriggerBulkUserCreationParams) {
  const response = await api.post(`admin/projects/${projectId}/create-users`, {
    json: {},
  });
  return response.status === 200;
}
