import { Link } from 'react-router-dom';
import { UserMenu } from '../UserMenu';
import { LanguageSelector } from '../LanguageSelector';
import { useTranslation } from '../../hooks';

export const Navbar = () => {
  const t = useTranslation();
  return (
    <header className="w-full border-b flex">
      <div className="m-auto w-full flex py-4 px-16 justify-between items-center max-w-screen-2xl">
        <div className="flex gap-8 items-center">
          <div className="w-11 h-11 select-none">
            <img
              src="/default-icon-72x72.png"
              alt="Mittwald Logo"
              className="w-full h-full object-contain rounded"
            />
          </div>
          <div className="flex items-center justify-center gap-6">
            <Link to="/dashboard/projects">
              <div className="text-md font-medium text-slate-700 select-none">
                {t('navbar.projects')}
              </div>
            </Link>
          </div>
        </div>

        <h1 className="text-xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight select-none">
          {t('navbar.title')}
        </h1>
        <div className="flex gap-4">
          <LanguageSelector />
          <UserMenu />
        </div>
      </div>
    </header>
  );
};
