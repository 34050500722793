import { Skeleton, cn } from 'me-component-library';

type Props = {
  count: number;
  itemClassName?: string;
};
export const SkeletonList: React.FC<Props> = ({ count, itemClassName }) => {
  const skeletonList = Array.from(Array(count).keys());

  return (
    <div className="contents" data-testid="skeleton-list">
      {skeletonList.map(item => (
        <Skeleton key={item} className={cn('w-40 h-40', itemClassName)} />
      ))}
    </div>
  );
};
