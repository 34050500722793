import { Label, LoadingSpinner } from 'me-component-library';
import { useTranslation } from '../../hooks';

type Props = {
  isVerified: boolean;
};

export const DomainSettingsStatusBox: React.FC<Props> = ({ isVerified }) => {
  const t = useTranslation();

  if (!isVerified) {
    return (
      <div className="w-56 flex flex-col items-center">
        <Label className="text-xl text-slate-700">
          {t('projectDetail.domain.fields.domainVerficationStatus.label')}
        </Label>
        <div className="flex justify-center">
          <LoadingSpinner />
        </div>
        <p className="mt-4 ">
          {t('projectDetail.domain.fields.domainVerficationStatus.waiting')}
        </p>
      </div>
    );
  }

  return (
    <div className="w-56 flex flex-col items-center">
      <Label className="text-xl text-slate-700">
        {t('projectDetail.domain.fields.domainVerficationStatus.label')}
      </Label>
      <p className="mt-4 text-green-500">
        {t('projectDetail.domain.fields.domainVerficationStatus.verified')}
      </p>
    </div>
  );
};
