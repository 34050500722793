import { Tabs, TabsList, TabsTrigger, TabsContent } from 'me-component-library';
import { useTranslation } from '../../hooks';
import { EmailTemplateForm } from './EmailTemplateForms';

export const EmailTemplates = () => {
  const t = useTranslation();
  return (
    <Tabs defaultValue="newUserInvite" className="w-full">
      <TabsList>
        <TabsTrigger value="newUserInvite">
          {t('projectDetail.emailTemplateForm.tabs.newUserInvite')}
        </TabsTrigger>
        <TabsTrigger value="resetPassword">
          {t('projectDetail.emailTemplateForm.tabs.resetPassword')}
        </TabsTrigger>
      </TabsList>
      <TabsContent value="newUserInvite">
        <EmailTemplateForm formType="newUserInvite" />
      </TabsContent>
      <TabsContent value="resetPassword">
        <EmailTemplateForm formType="resetPassword" />
      </TabsContent>
    </Tabs>
  );
};
