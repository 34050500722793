import { useMutation, useQueryClient } from 'react-query';
import { CreateUserParams, createUser } from '../../api/createUser';
import { useToast } from 'me-component-library';
import { useTranslation } from '../useTranslation';

export function useCreateUser() {
  const t = useTranslation();
  const queryClient = useQueryClient();
  const { toast } = useToast();
  return useMutation((variables: CreateUserParams) => createUser(variables), {
    onSuccess: (_, { projectId, email }) => {
      queryClient.invalidateQueries([`users`, projectId]);
      toast({
        title: t('projectDetail.addUserForm.toast.success.title'),
        description: t('projectDetail.addUserForm.toast.success.description', {
          email,
        }),
      });
    },
    onError: (_, { email }) => {
      toast({
        title: t('projectDetail.addUserForm.toast.error.title'),
        description: t('projectDetail.addUserForm.toast.error.description', {
          email,
        }),
        variant: 'error',
      });
    },
  });
}
