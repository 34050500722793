export enum PATHS {
  INDEX = '/',
  NOTFOUND = '*',
  LOGIN = 'login',
  DASHBOARD = 'dashboard',
  PROJECTS = 'projects',
  ENTITY = ':id',
  OAUTH_CALLBACK = 'oauth/callback',
  OAUTH_ENTRYPOINT = 'oauth/entrypoint',
}
