
import { Outlet } from 'react-router-dom';
import { Navbar } from '../Navbar';
import { Toaster } from 'me-component-library';
import { Footer } from '..';
import React from 'react';

declare global {
  interface Window {
    projectId: string;
  }
}

export const Layout: React.FC = () => {

  return (
    <>
      <Navbar />

      <div className="min-h-screen max-w-screen-2xl px-16 m-auto mt-12">
        <Outlet />
      </div>
      <Toaster />
      <Footer />
    </>
  );
};
