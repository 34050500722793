import { getPrimaryValues, Theme } from 'design-tokens';

type ThemeStyleValues = {
  background: string;
  color: string;
  borderColor: string;
  shadowColor: string;
};

export function useGetWhiteLabelThemes() {
  const availableThemes = Object.keys(getPrimaryValues()) as Theme[];

  function createThemesStyle() {
    return availableThemes.reduce(
      (acc, theme) => ({
        ...acc,
        [theme]: {
          background: `bg-white-label-${theme}`,
          color: `text-white-label-${theme}`,
          borderColor: `border-white-label-${theme}`,
        },
      }),
      {} as Record<Theme, ThemeStyleValues>,
    );
  }

  function getSelectedThemeStyle(theme?: Theme) {
    const themesStyle = createThemesStyle();

    if (!theme) {
      return themesStyle.black;
    }

    return themesStyle[theme];
  }

  return { availableThemes, getSelectedThemeStyle };
}
