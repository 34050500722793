import { api } from './api';

export type TestCustomSMTPSenderParams = {
  projectId: string;
  testTargetEmailAddress: string;
};

export async function testCustomSMTPSender({
  projectId,
  testTargetEmailAddress,
}: TestCustomSMTPSenderParams) {
  const response = await api.post(`admin/projects/${projectId}/test-custom-smtp`, {
    json: {
      to: testTargetEmailAddress,
    },
  });
  return response.status === 200;
}
