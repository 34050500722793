import { useMutation, useQueryClient } from 'react-query';
import { uploadImage } from '../../api';
import { UploadProjectLogoAttributes } from '../../api';

type UploadImageAttributes = Pick<
  UploadProjectLogoAttributes,
  'image' | 'imageType'
>;

export function useUploadImage(projectId: string) {
  const queryClient = useQueryClient();
  const { mutate, data, isLoading } = useMutation(
    ({ image, imageType }: UploadImageAttributes) =>
      uploadImage({ image, projectId, imageType }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['projects', projectId]);
      },
    },
  );
  return {
    mutate,
    data,
    isLoading,
  };
}
