import { useMutation } from 'react-query';
import { useToast } from 'me-component-library';
import {
  TriggerInviteEmailResendParams,
  triggerInviteEmailResend,
} from '../../api';
import { useTranslation } from '..';

export function useTriggerInviteEmailResend() {
  const { toast } = useToast();
  const t = useTranslation();

  return useMutation(
    (variables: TriggerInviteEmailResendParams) =>
      triggerInviteEmailResend(variables),
    {
      onSuccess: () => {
        toast({
          title: t('projectDetail.userList.toast.success.title'),
          description: t('projectDetail.userList.toast.success.description'),
        });
      },
      onError: () => {
        toast({
          variant: 'error',
          title: t('projectDetail.userList.toast.error.title'),
          description: t('projectDetail.userList.toast.error.description'),
        });
      },
    },
  );
}
