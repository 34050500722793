import { useParams } from 'react-router-dom';
import {
  useGetProjectDetail,
  useTranslation,
  useUploadImage,
} from '../../hooks';
import {
  Dropzone,
  DropzoneAccept,
  DropzoneIdle,
  DropzoneReject,
  Icon,
  Skeleton,
  cn,
  useToast,
} from 'me-component-library';
import { ImageTypes, Project } from '../../types';
import { useMemo } from 'react';

export type ImageDropZoneProps = {
  acceptedFiles: string[];
  imageType: ImageTypes;
};

/**
 * Get image from data depending on ImageType.
 *
 * @param none
 * @returns {[string, string]} An array where:
 *   - [0] is the name of the image.
 *   - [1] is the URL of the image.
 */
const getImage = (
  projectData: Project | undefined,
  imageType: ImageTypes,
): [string, string] => {
  if (projectData === undefined) return ['', ''];

  switch (imageType) {
    case ImageTypes.LOGO:
      return ['logo', projectData?.logo];
    case ImageTypes.IMGLOGIN:
      return ['title image', projectData?.imgLogin];
    case ImageTypes.FAVICON:
      return ['favicon', projectData?.favicon];
    case ImageTypes.TOPBANNER:
      return ['top banner', projectData?.topImage];
    default:
      return ['', ''];
  }
};

const convertImageTypeToParam = (imageType: ImageTypes) => {
  switch (imageType) {
    case ImageTypes.LOGO:
      return 'logo';
    case ImageTypes.IMGLOGIN:
      return 'title';
    case ImageTypes.FAVICON:
      return 'favicon';
    case ImageTypes.TOPBANNER:
      return 'topImage';
  }
};

export const ImageDropZone: React.FC<ImageDropZoneProps> = ({
  acceptedFiles,
  imageType,
}) => {
  const t = useTranslation();
  const params = useParams();
  const { data, isLoading: isDetailsLoading } = useGetProjectDetail(
    params.id as string,
  );
  const { mutate: uploadImage, isLoading: isUploadLoading } = useUploadImage(
    params.id as string,
  );
  const { toast } = useToast();

  /**
   * Get memoized image from data depending on ImageType.
   *
   * @returns {[string, string]} An array where:
   *   - [0] is the name of the memoized image.
   *   - [1] is the URL of the memoized image.
   */
  const memoizedGetImage = useMemo(
    () => getImage(data, imageType),
    [data, imageType],
  );

  function onDropAccepted(acceptedFiles: File[]) {
    uploadImage(
      {
        image: acceptedFiles[0],
        imageType: convertImageTypeToParam(imageType),
      },
      {
        onError: () => {
          toast({
            title: t(
              'projectDetail.uploadLogoDropzone.errors.uploadFailed.title',
            ),
            description: t(
              'projectDetail.uploadLogoDropzone.errors.uploadFailed.description',
            ),
            variant: 'error',
          });
        },
      },
    );
  }

  function onDropRejected() {
    toast({
      title: t(
        'projectDetail.uploadLogoDropzone.errors.wrongFileExtension.title',
      ),
      description: t(
        'projectDetail.uploadLogoDropzone.errors.wrongFileExtension.description',
        { allowedFileTypes: acceptedFiles.join(', ') },
      ),
      variant: 'error',
    });
  }

  return (
    <Dropzone
      isLoading={isUploadLoading}
      options={{
        accept: {
          'image/*': acceptedFiles,
        },
        maxSize: 5000000,
        maxFiles: 1,
        multiple: false,
        onDropAccepted: onDropAccepted,
        onDropRejected: onDropRejected,
        disabled: isUploadLoading,
      }}
      className={cn(
        "group h-36 w-36 flex flex-col items-center justify-center border border-slate-300 rounded-md p-2 overflow-hidden relative text-center cursor-pointer data-[status='accept']:border-green-600  data-[status='reject']:border-red-600",
        isUploadLoading && 'pointer-events-none cursor-wait',
      )}
    >
      {!isDetailsLoading ? (
        <img
          src={
            memoizedGetImage[1] === ''
              ? '/default-icon-none-144x144.png'
              : memoizedGetImage[1]
          }
          alt={memoizedGetImage[0]}
          className="h-full w-full object-contain"
          data-testid={memoizedGetImage[0] + '-image'}
        />
      ) : (
        <Skeleton className="w-full h-full" />
      )}
      <DropzoneIdle
        className={cn(
          'flex transition-all justify-center items-center flex-col opacity-0 p-2 text-center absolute top-0 left-0 w-full h-full bg-black/70 gap-2 group-hover:opacity-100 backdrop-blur-sm',
          isUploadLoading && 'hidden',
        )}
      >
        <Icon name="fileUp" className="stroke-white" />
        <h3 className="text-white text-xs">
          {t('projectDetail.uploadLogoDropzone.idle')}
        </h3>
      </DropzoneIdle>
      <DropzoneAccept
        className={cn(
          'flex transition-all justify-center items-center flex-col p-2 text-center absolute top-0 left-0 w-full h-full bg-green-500/70 gap-2 backdrop-blur-sm',
          isUploadLoading && 'hidden',
        )}
      >
        <Icon name="check" size={40} className="stroke-white" />
        <h3 className="text-white text-xs">
          {t('projectDetail.uploadLogoDropzone.accept')}
        </h3>
      </DropzoneAccept>
      <DropzoneReject
        className={cn(
          'flex transition-all justify-center items-center flex-col p-2 text-center absolute top-0 left-0 w-full h-full bg-red-500/70 gap-2 backdrop-blur-sm',
          isUploadLoading && 'hidden',
        )}
      >
        <Icon name="x" size={40} className="stroke-white" />
        <p className="text-white text-xs">
          {t('projectDetail.uploadLogoDropzone.reject', {
            allowedFileTypes: acceptedFiles.join(', '),
          })}
        </p>
      </DropzoneReject>
    </Dropzone>
  );
};
