import { ProjectContainer } from '../../components/ProjectContainer';
import { useTranslation } from '../../hooks';

export const Projects: React.FC = () => {
  const t = useTranslation();

  return (
    <div className="relative">
      <h1 className="text-3xl">{t('projects.title')}</h1>
      <ProjectContainer />
    </div>
  );
};
