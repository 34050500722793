import { Project } from '../types';
import { api } from './api';

type ProjectDetails = Partial<
  Pick<Project, 'domain' | 'theme' | 'name' | 'logo'>
>;

export type UpdateProjectDetails = ProjectDetails & { projectId: string };

export async function updateProjectDetails({
  projectId,
  ...projectDetails
}: UpdateProjectDetails): Promise<Project> {
  const validProjectDetails = Object.entries(projectDetails).reduce<{
    [key: string]: string | File;
  }>((acc, [key, value]) => {
    if (value && key) {
      acc[key] = value;
    }
    return acc;
  }, {});

  const response = await api.patch(`admin/projects/${projectId}`, {
    json: {
      ...validProjectDetails,
    },
  });

  return response.json();
}
