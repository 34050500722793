import { Avatar, AvatarFallback, Icon } from 'me-component-library';
import { useMemo } from 'react';
import { useUser } from '../../hooks';

export const UserAvatar: React.FC = () => {
  const { data } = useUser();

  const firstTwoLetterOfEmail = useMemo(() => {
    if (!data?.email) {
      return '?';
    }
    return data?.email.slice(0, 2);
  }, [data?.email]);

  return (
    <div className="flex items-center gap-2">
      <Avatar>
        <AvatarFallback>{firstTwoLetterOfEmail}</AvatarFallback>
      </Avatar>
      <Icon name="chevronDown" size={20} />
    </div>
  );
};
