import { UserData } from '../types';
import { api } from './api';

export type CreateUserParams = { projectId: string } & Pick<
  UserData,
  'email' | 'company' | 'emailId' | 'lastName' | 'firstName' | 'roles'
>;

export async function createUser({
  projectId,
  ...user
}: CreateUserParams): Promise<{ id: string }> {
  const response = await api.post(`admin/projects/${projectId}/users`, {
    json: {
      ...user,
    },
  });
  return await response.json();
}
