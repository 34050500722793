import { useQuery } from 'react-query';
import { getDomainVerification } from '../../api';

export function useGetDomainVerification(projectId: string) {
  return useQuery(
    ['domainVerification', projectId],
    () => getDomainVerification(projectId),
    {
      refetchOnWindowFocus: true,
      staleTime: 5000,
      refetchInterval: 5000,
    },
  );
}
