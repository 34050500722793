import { Link, useParams } from 'react-router-dom';
import { PATHS } from '../../types';
import { useGetProjectDetail, useTranslation } from '../../hooks';
import { Skeleton } from 'me-component-library';

export const ProjectBreadcrumb: React.FC = () => {
  const params = useParams();
  const { data, isLoading } = useGetProjectDetail(params.id as string);
  const t = useTranslation();

  return (
    <>
      <div className="flex gap-2 my-8 text-slate-400">
        {!isLoading ? (
          <>
            <Link to={`/${PATHS.DASHBOARD}/${PATHS.PROJECTS}`}>
              {t('projectDetail.breadcrumb.projects')}
            </Link>
            /
            <Link
              className="text-slate-800 overflow-hidden overflow-ellipsis whitespace-nowrap max-w-full"
              to={`/${PATHS.DASHBOARD}/${PATHS.PROJECTS}/${data?.projectId}`}
            >
              {data?.name}
            </Link>
          </>
        ) : (
          <Skeleton className="w-1/3 h-6" />
        )}
      </div>
    </>
  );
};
