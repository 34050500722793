type CopyToClipboardOptions = {
  onSuccess?: () => void;
  onError?: () => void;
};

export const copyToClipboard = async (
  textToCopy: string,
  options?: CopyToClipboardOptions,
) => {
  try {
    await navigator.clipboard.writeText(textToCopy);
    options?.onSuccess?.();
  } catch (err) {
    options?.onError?.();
  }
};
