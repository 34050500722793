import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { routes } from './routes';
import './i18n/config';

function App() {
  const queryClient = new QueryClient();
  const router = createBrowserRouter(routes);
  return (
    <QueryClientProvider client={queryClient} contextSharing={true}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  );
}

export default App;
