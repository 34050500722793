import { PropsWithChildren, useEffect } from 'react';
import { useUser } from '../../hooks';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '../../types';

export const PrivateRoutes: React.FC<PropsWithChildren> = ({ children }) => {
  const { data, isLoading } = useUser({
    navigateToLoginOnError: true,
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading && !data) {
      navigate(`/${PATHS.LOGIN}`);
    }
  }, [data, navigate, isLoading]);

  if (isLoading) {
    return null;
  }

  return <>{children}</>;
};
