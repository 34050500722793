import { DomainSettingsEdit } from './DomainSettingsEdit';
import { useGetProjectDetail } from '../../hooks';
import { useParams } from 'react-router-dom';
import { DomainSettingsUnset } from './DomainSettingsUnset';

export const DomainSettingsForm = () => {
  const params = useParams();
  const { data } = useGetProjectDetail(params.id as string);

  if (data === undefined) {
    return 'Loading...';
  }

  if (
    data.domain == 'postmaster.digital' ||
    data.domain === '' ||
    data.domain === 'www.postmaster.digital'
  ) {
    return <DomainSettingsUnset />;
  } else {
    return <DomainSettingsEdit data={data} />;
  }
};
