import { LoadingSpinner } from 'me-component-library';

export const CenteredFullScreenLoadingSpinner = () => {
  return (
    <div className="w-full bg-gray-200 h-screen flex items-center justify-center">
      <div className="flex justify-center">
        <LoadingSpinner />
      </div>
    </div>
  );
};
