import { useMutation, useQueryClient } from 'react-query';
import { useToast } from 'me-component-library';
import { useTranslation } from '..';
import {
  TriggerBulkUserCreationParams,
  triggerBulkUserCreation,
} from '../../api';

export function useTriggerBulkUserCreation() {
  const { toast } = useToast();
  const t = useTranslation();
  const queryClient = useQueryClient();

  return useMutation(
    (variables: TriggerBulkUserCreationParams) =>
      triggerBulkUserCreation(variables),
    {
      onSuccess: (_, { projectId }) => {
        queryClient.invalidateQueries([`users`, projectId]);

        toast({
          title: t('projectDetail.userList.bulkCreate.toast.success.title'),
          description: t(
            'projectDetail.userList.bulkCreate.toast.success.description',
          ),
        });
      },
      onError: () => {
        toast({
          variant: 'error',
          title: t('projectDetail.userList.bulkCreate.toast.error.title'),
          description: t(
            'projectDetail.userList.bulkCreate.toast.error.description',
          ),
        });
      },
    },
  );
}
